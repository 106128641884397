.menu-mobile {
  background: #0d678d;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  position: relative;
  z-index: 1;

  &.closed {
    height: 68px;
    border-radius: 0 0 20px 20px;

    .menu-ul {
      .menu-li {
        display: none;

        &.active {
          display: flex;
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }

  &.opened {
    height: 816px;
  }

  .menu-ul {
    list-style: none;
    padding: 0;

    .menu-li {
      padding: 14px 18px 14px 43px;
      display: flex;

      &.active {
        background: #0a5271;
      }

      a {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        color: white;

        &:hover {
          text-decoration: none;
        }

        img {
          &.active {
            background: #27aae2;
            border-radius: 12px;
          }
        }

        .menu-label-name {
          margin-left: 14px;
          width: 143px;
          white-space: pre-wrap;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 16px;
        }

        .message_notify {
          margin-left: 25px;
        }
      }
    }
  }

  .btn-mobile-close {
    position: absolute;
    top: 16px;
    right: 18px;
  }
}

@media (max-width: 575px) {

  .custom-logout {
    font-family: 'Open Sans' !important;
    font-weight: 400 !important;
  }

}