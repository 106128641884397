.message_notify {
  height: 25px;
  width: 25px;
  flex-shrink: 0;
  background-color: #40cc7b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;

  span {
    align-self: center;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }
}
