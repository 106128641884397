#root {
  .menu-nav {
    background-color: rgba(13, 103, 141, 1);
    border-radius: 0 30px 30px 0;
    max-width: 287px;
    width: 287px;
    position: relative;
    .menu-logo {
      img {
        margin-top: 27px;
      }
    }

    .menu-ul {
      margin-top: 93px;
      position: relative;

      .menu-li {
        width: 257px;
        height: 68px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          height: 42px;
          padding-left: 45px;
          background: none;

          .menu-label-name {
            font-family: Work Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            width: 143px;
            white-space: pre-wrap;
          }

          .active {
            background: #27aae2;
            border-radius: 12px;
          }

          img {
            margin-right: 14px;
          }
        }
      }

      .active {
        background: #0a5271;
        border-radius: 0 20px 20px 0;

        a {
          border-right: none;
        }
      }
    }

    .bottom-onboarding {
      position: absolute;
      bottom: 36px;
      padding-left: 46px;
      span {
        padding-left: 12px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }
    }
  }
}
