a {
  color: inherit;
  text-decoration: none;
}

.ant-dropdown-menu {
  border-radius: 22px !important;
  overflow: hidden;
  min-width: 195px;
}

.ant-dropdown-menu-item {
  padding-left: 22px;
  padding-right: 22px;
  border-bottom: solid 1px rgba(117, 124, 127, 0.1);
}

.ant-dropdown-menu-item:last-child {
  border-bottom: none;
}

.ant-dropdown-menu-item:hover {
  color: #2d9cdb;
  background-color: unset;
}

.external-layout {
  background: linear-gradient(
    180deg,
    #eff6fd 0%,
    rgba(248, 249, 254, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Work Sans', sans-serif;
  min-height: 100vh;
  overflow-x: hidden;

  .layout {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }

  .external-header {
    background: #ffffff;
    width: 100%;

    .logo {
      cursor: pointer;
    }
    .box {
      height: 84px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu {
        display: flex;
        align-items: center;

        .link {
          height: 30px;
          line-height: 30px;
          color: #231f20;
          font-size: 14px;
          padding: 0 13px;
          margin: 0 2px;
          cursor: pointer;
          transition-duration: 0.3s;
        }

        .active-link,
        .link:hover {
          color: #27aae2;
          background: #27aae21a;
          border-radius: 15px;
        }

        .button {
          background: #27aae2;
          border-radius: 19px;
          font-weight: bold;
          font-size: 16px;
          line-height: 38px;
          text-transform: uppercase;
          text-align: center;
          height: 38px;
          width: 110px;
          color: #ffffff;
          cursor: pointer;
          margin-left: 20px;

          &-green {
            background: #40cc7b;
            &:hover {
              background: #30bb6b;
            }
          }
        }
      }

      .menu-button {
        display: none;
        cursor: pointer;
      }
      .mobile-menu {
        display: none;
        .hide {
          display: none;
        }

        .show {
          display: block;
          position: fixed;
          top: 54px;
          left: 0;
          width: 100%;
          height: calc(100vh - 54px);
          background: #ffffff;
          z-index: 10;
          .menu {
            flex-direction: column;
            .button {
              margin-left: 0;
            }
            div {
              margin-top: 25px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .box {
        padding: 0 25px;
        height: 52px;
        .menu {
          display: none;
        }
        .menu-button {
          display: block;
        }
        .logo {
          img {
            width: 150px;
          }
        }
        .mobile-menu {
          display: contents;
          .menu {
            display: flex;
          }
        }
      }
    }
  }

  .external-footer {
    background: #27aae2;
    border-radius: 46px 46px 0px 0px;
    width: 100%;

    .title {
      font-size: 24px;
      color: #ffffff;
      line-height: 90px;
      text-align: center;

      .link {
        font-weight: 600;
        cursor: pointer;
      }
      img {
        transition-duration: 0.3s;
      }

      .link:hover img {
        margin-left: 5px;
      }
    }

    .content {
      background: #f1f7fd;
      border-radius: 46px 46px 0px 0px;

      .logo {
        width: 200px;
      }

      .row {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
        padding: 0 20px;
        flex-wrap: nowrap;
        margin: 0;
      }
      .text {
        font-style: italic;
        font-weight: 600;
        font-size: 24px;
      }
      .social {
        display: flex;

        div {
          margin: 0 6px;
          background: #27aae21a;
          border-radius: 20px;
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .small {
        font-size: 14px;
        line-height: 16px;
        color: #231f20;
        opacity: 0.5;
        cursor: pointer;
      }
      .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        cursor: pointer;
        flex-wrap: wrap;

        .link {
          font-size: 20px;
          line-height: 30px;
          color: #27aae2;
          padding: 0 13px;
          margin: 0 10px;
          transition-duration: 0.3s;
        }
        .link:hover {
          background: #27aae21a;
          border-radius: 15px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      .title {
        height: 92px;
        padding: 15px;
        span {
          display: block;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .content {
        .row {
          flex-direction: column;
          height: 170px;
          justify-content: space-around;
          padding: 10px;
        }
        .logo {
          width: auto;
        }
        .text {
          font-size: 18px;
        }
        .menu {
          flex-wrap: wrap;
          width: 350px;
          margin: 0 auto;
          .link {
            width: calc(50% - 20px);
          }
        }
      }
    }
  }

  .footer {
    background: #27aae2;
    border-radius: 46px 46px 0px 0px;
    width: 100%;

    .title {
      font-size: 24px;
      color: #ffffff;
      line-height: 90px;
      text-align: center;
      // font-weight: lighter;
      margin: 40px;

      .link {
        font-weight: 600;
        cursor: pointer;
        color: #ffffff !important;
      }
      img {
        transition-duration: 0.3s;
      }

      .link:hover {
        color: inherit;
      }

      .link:hover img {
        margin-left: 5px;
      }
    }

    .content {
      background: #f1f7fd;
      border-radius: 46px 46px 0px 0px;
      max-width: 100%;

      .logo {
        width: 200px;
      }

      .row {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
        padding: 0 20px;
        flex-wrap: nowrap;
        margin: 0;
      }
      .text {
        font-style: italic;
        font-weight: 600;
        font-size: 24px;
      }
      .small {
        font-size: 14px;
        line-height: 16px;
        color: #231f20;
        opacity: 0.5;
      }
      .menu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 1200px;
        margin: 0 auto;
        row-gap: 10px;

        .link {
          color: rgba(0, 0, 0, 0.85) !important;
          font-size: 16px;
        }

        .link:hover {
          color: #1890ff !important;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      .title {
        height: 92px;
        padding: 15px;
        span {
          display: block;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .content {
        .row {
          flex-direction: column;
          height: 170px;
          justify-content: space-around;
          padding: 10px;
        }
        .logo {
          width: auto;
        }
        .text {
          font-size: 18px;
        }
        .menu {
          flex-wrap: wrap;
          // width: 350px;
          margin: 0 auto;
          .link {
            // width: calc(50% - 20px);
          }
        }
      }
    }
  }

  .social {
    display: flex;

    div {
      margin: 0 6px;
      background: #27aae21a;
      border-radius: 20px;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    div:hover {
      background: #27aae233;
    }
  }

  .header {
    background: #ffffff;
    width: 100%;

    .logo {
      cursor: pointer;
    }
    .box {
      height: 84px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu {
        display: flex;
        align-items: center;

        .link {
          height: 30px;
          line-height: 30px;
          color: #231f20;
          font-size: 14px;
          padding: 0 13px;
          margin: 0 2px;
          cursor: pointer;
          transition-duration: 0.3s;
          white-space: nowrap;

          .icon {
            vertical-align: middle;
            display: inline-block;
            padding-left: 7px;

            svg {
              vertical-align: unset;
            }
          }
        }

        .active-link,
        .link:hover {
          color: #27aae2;
          background: #27aae21a;
          border-radius: 15px;
          svg path {
            stroke: #27aae2;
          }
        }

        .button {
          background: #27aae2;
          border-radius: 19px;
          font-weight: bold;
          font-size: 16px;
          line-height: 38px;
          text-transform: uppercase;
          text-align: center;
          height: 38px;
          // width: 110px;
          color: #ffffff;
          cursor: pointer;
          margin-left: 20px;

          &-green {
            background: #40cc7b;
            &:hover {
              background: #30bb6b;
            }
          }
        }
      }

      .menu-button {
        display: none;
        cursor: pointer;
      }
      .mobile-menu {
        display: none;
        .hide {
          display: none;
        }

        .show {
          display: block;
          position: fixed;
          top: 54px;
          left: 0;
          width: 100%;
          height: calc(100vh - 54px);
          background: #ffffff;
          z-index: 10;
          .menu {
            flex-direction: column;
            .button {
              margin-left: 0;
            }
            div {
              margin-top: 25px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .box {
        padding: 0 25px;
        height: 52px;
        .menu {
          display: none;
        }
        .menu-button {
          display: block;
        }
        .logo {
          img {
            width: 150px;
          }
        }
        .mobile-menu {
          display: contents;
          .menu {
            display: flex;
          }
        }
      }
    }
  }
}

.disableScroll {
  overflow: hidden;
  height: 100vh;
}
