@import 'sidebar';

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans.ttf');
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans.ttf');
  font-style: bold;
  font-weight: 600;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans.ttf');
  font-style: bold;
  font-weight: 700;
}

.react-pdf__Page {
  &__svg {
    margin: 0 auto;
  }

  &__textContent {
    top: 49.5% !important;
  }
}
